import React from "react";
import { Link } from "react-router-dom";
// import HeaderImage from "../../../components/HeaderImage";

const Institutional: React.FC = () => {
  // const handleDocumentClick = () => {
  //   window.open(
  //     "/assets/pdf/Institutonal-Open-Science-Policy-Template.pdf",
  //     "_blank"
  //   );
  // };
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE’s new initiative,
        <Link to="" className="text-primary">
          {" "}
          LIBSENSE-Connect,
        </Link>{" "}
        has been mobilising infrastructure and software developers to interact
        with researchers and librarians to explore common grounds for working to
        bridge the infrastructure deficits currently existing on this side of
        Africa.
      </p>

      {/* Buttons */}
      {/* <div className="flex justify-center space-x-4 mt-8">
        <Link
          to="https://indico.wacren.net/category/41/"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Events
        </Link>
        <Link
          to="https://photos.wacren.net/index.php?/category/115"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Photos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Videos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Document
        </Link>
      </div> */}
    </>
  );
};

export default Institutional;
