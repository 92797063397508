import React from "react";
import { useParams } from "react-router-dom";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";
import Institutional from "./OPS/Institution";
import NationalPage from "./OPS/National";
import RegionalPage from "./OPS/Regional";
// import ContinentalPage from "./OPS/Continental";

const OpenPolicyPage: React.FC = () => {
  const { policyType } = useParams<{ policyType: string }>(); // Get dynamic URL part
  const sections = ["Institutional", "National", "Regional"];

  const content = {
    institutional: <Institutional />,
    national: <NationalPage />,
    regional: <RegionalPage />,
    // continental: <ContinentalPage />,
  };

  return (
    <>
      <HeaderImage />
      <WhatWeDo
        sections={sections}
        content={content}
        title="Open Science Policy & Governance"
        paragraphs={[
          "At LIBSENSE, we are committed to fostering a culture of open science across African higher education institutions. We do this on two fronts: through targeted engagements with institution leaders and through the LIBSENSE Early Career Researchers (ECR) initiative. ",
        ]}
        initialSection={policyType?.toLowerCase() || "institutional"}
        route="open-science-policy"
      />
    </>
  );
};

export default OpenPolicyPage;
