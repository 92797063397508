import React from "react";
import WhatWeDo from "../../components/ReusableData";
import HeaderImage from "../../components/HeaderImage";
import { useParams } from "react-router-dom";
import Institutional from "./OAI/Institution";
import NationalPage from "./OAI/National";
import RegionalPage from "./OAI/Regional";
// import ContinentalPage from "./OAI/Continental";

const OpenAccessPage: React.FC = () => {
  const { policyType } = useParams<{ policyType: string }>(); // Get dynamic URL part
  const sections = ["Institutional", "National", "Regional"];

  const content = {
    institutional: <Institutional />,
    national: <NationalPage />,
    regional: <RegionalPage />,
    // continental: <ContinentalPage />,
  };

  return (
    <>
      <HeaderImage />
      <WhatWeDo
        sections={sections}
        content={content}
        title="Open Access Infrastruture"
        paragraphs={[
          "LIBSENSE is advancing open science across Africa through a multi-faceted approach. Its efforts focus on improving access to scientific knowledge and fostering a culture of collaboration and transparency in research. Key initiatives include building institutional open science capacity, national open science policies, governance and shared infrastructure, regional leadership policy engagement and continental open science infrastructure.",
        ]}
        initialSection={policyType?.toLowerCase() || "institutional"}
        route="open-infrastructure"
      />
    </>
  );
};

export default OpenAccessPage;
