import React, { useEffect } from "react";
import CollaborationCard from "../../components/CollaborationsCard";
import HeaderImage from "../../components/HeaderImage";
import { Link } from "react-router-dom";

const Collaborations: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("collaborations");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <HeaderImage />

      <div className="max-w-4xl mx-auto mb-12 p-4" id="collaborations">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Collaborations
        </h2>
        <h3 className="text-sm font-semibold mb-4">
          LIBSENSE-RUFORUM Cooperation
        </h3>
        <p className="text-sm text-foreground mt-4">
          LIBSENSE and RUFORUM (Regional Universities Forum for Capacity
          Building in Agriculture) have forged a strategic partnership to
          strengthen open science practices in African universities. The
          partnership aims to enhance universities' capacity to manage research
          data and outputs, promotes transparency in research, and supports the
          development of policies that encourage knowledge sharing, ultimately
          strengthening the continent's contribution to global scientific
          efforts.
        </p>
        <p className="text-sm text-foreground mt-4">
          In this regard,{" "}
          <Link
            target="_blank"
            to="https://wacren.net/en/"
            className="text-primary"
          >
            WACREN
          </Link>{" "}
          (initiator of LIBSENSE) and{" "}
          <Link
            target="_blank"
            to="https://www.ruforum.org/"
            className="text-primary"
          >
            RUFORUM
          </Link>{" "}
          signed a two-year memorandum of understanding (2021-2023) to enhance
          scientific and scholarly communication in Africa, through the{" "}
          <Link
            target="_blank"
            to="https://africaconnect3.net/"
            className="text-primary"
          >
            AfricaConnect3
          </Link>{" "}
          project. Under this MoU, the parties agreed to collaborate in LIBSENSE
          to provide contemporary platforms to enable agriculture research data
          sharing and open access publishing. As part of the collaboration,
          training on research data management will be developed as repeatable
          open education resources (OER) for RUFORUM researchers and librarians.
        </p>
        <p className="text-sm text-foreground my-4">
          This MoU was renewed in 2023, spanning another two years.
        </p>

        <h4 className="text-sm font-semibold mb-4">Key Focus Areas:</h4>

        <ul className="text-sm text-foreground mb-14">
          <li>
            <p className="text-sm mb-4">
              1. Capacity Building in Research Data Management (RDM):
            </p>
            <p className="text-sm mb-4">
              The collaboration delivers targeted training and workshops for
              researchers and librarians at RUFORUM member universities,
              equipping them with the skills to manage research data
              effectively. This includes best practices in data curation,
              sharing, and open access repositories.
            </p>
          </li>
          <li>
            <p className="text-sm mb-4">
              2. Development of Open Access Repositories:
            </p>
            <p className="text-sm mb-4">
              LIBSENSE supports RUFORUM universities in establishing and
              enhancing institutional repositories to store, preserve, and
              disseminate research outputs. The repository facilitates open
              access to research publications and datasets, making African
              agricultural research more visible and accessible globally. WACREN
              avails its BAOBAB repository to the 160 RUFORUM universities.
            </p>
          </li>
          <li>
            <p className="text-sm mb-4">3. Promoting Open Science Policies:</p>
            <p className="text-sm mb-4">
              Through this collaboration, LIBSENSE and RUFORUM advocate for
              integrating open science policies at institutional and national
              levels. This ensures that researchers are encouraged to share
              their work openly, contributing to the global research community
              while addressing regional agricultural challenges.
            </p>
          </li>
          <li>
            <p className="text-sm mb-4">4. Enhancing Research Impact:</p>
            <p className="text-sm mb-4">
              By fostering a culture of open access and data sharing, the
              collaboration helps to increase the visibility and impact of
              research conducted at RUFORUM universities. This is particularly
              important in agriculture, where research outputs can directly
              contribute to Africa's food security and sustainable development.
            </p>
          </li>
        </ul>

        <div className="space-y-8">
          <CollaborationCard
            title="LIBSENSE-EIFL Collaboration on No-Fee Open Publishing"
            paragraphs={[
              "WACREN (through LIBSENSE), EIFL, and AJOL (African Journals Online), with support from Wellcome, launched a three-year project (2023-2025) to strengthen the quality and sustainability of diamond open access (OA) publishing services across Africa.",
              "The project has identified challenges for diamond OA publishing in Africa through community consultation and a landscape study. In 2024, the first of two open calls for proposals for funding to strengthen the quality of diamond OA publishing services across the region was issued. The funding process will be participatory, with input and advice from the African diamond OA community.",
            ]}
            link="https://www.eifl.net/news/new-project-strengthen-diamond-oa-africa"
          />
        </div>
      </div>
    </>
  );
};

export default Collaborations;
