import React from "react";
// import { Link } from "react-router-dom";

const RegionalPage: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE engages in various activities to foster the development and
        implementation of open science policies across Africa. LIBSENSE
        collaborates with regional higher education regulatory and oversight
        bodies to establish comprehensive, cross-cutting regional open science
        policies. These collaborations help to align regional policies with the
        openness, transparency, and inclusivity principles outlined in the
        UNESCO Recommendations on Open Science.
      </p>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE organises annual research and education leadership meetings to
        address themes requiring regional collaboration. These meetings aim to
        foster active engagement among university leaders, regulators, and
        research funders, highlight recent policy advancements and explore
        opportunities for national and international cooperation. These meetings
        also promote leadership in research excellence and the establishment of
        a sustainable research ecosystem
      </p>

      {/* Buttons */}
      {/* <div className="flex justify-center space-x-4 mt-8">
        <Link
          to="https://indico.wacren.net/category/38/"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Events
        </Link>
        <Link
          to="https://photos.wacren.net/index.php?/category/29"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Photos
        </Link>
        <Link
          to="https://video.wacren.net/channel/LIBSENSE/595033"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Videos
        </Link>
        <Link
          to="/assets/pdf/Institutonal-Open-Science-Policy-Template.pdf"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Document
        </Link>
      </div> */}
    </>
  );
};

export default RegionalPage;
