import React, { useEffect } from "react";
import CollaborationCard from "../../components/CollaborationsCard";
// import { NavLink } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const ImpactSection: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("impact");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <HeaderImage />

      <div className="max-w-4xl mx-auto mb-12 p-4" id="impact">
        <h2 className="text-center text-xxl font-bold text-foreground mb-8">
          Impact
        </h2>

        <div className="space-y-8">
          <CollaborationCard
            title="Open Science Policy"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />

          <CollaborationCard
            title="Research Data Management Practices"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <CollaborationCard
            title="Capacity Building"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        </div>

        {/* Video Testimonies */}
        <div className="grid grid-cols-1 md:grid-cols-3 mt-8 space-x-4">
          {/* Video 1 */}
          <div className="flex flex-col justify-center items-center gap-2">
            <iframe
              id="kaltura_player"
              src="https://api.kaltura.nordu.net/p/384/sp/38400/embedIframeJs/uiconf_id/23453416/partner_id/384?iframeembed=true&playerId=kaltura_player&entry_id=0_oealzvn5&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_yat2r91k"
              width="100%"
              height="255"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
              title="LIBSENSE Leadership Meeting III Abuja 2024 - video highlight"
            ></iframe>

            <div className="block text-center mb-4 p-2 rounded-md text-foreground transition duration-300">
              Video Testimony
            </div>
          </div>

          {/* Video 2 */}
          <div className="flex flex-col justify-center items-center gap-2">
            <iframe
              id="kaltura_player"
              src="https://api.kaltura.nordu.net/p/384/sp/38400/embedIframeJs/uiconf_id/23453416/partner_id/384?iframeembed=true&playerId=kaltura_player&entry_id=0_npcsnamn&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_6loqx7vz"
              width="100%"
              height="255"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
              title="LIBSENSE-Connect II - Abuja 2024_video highlight"
            ></iframe>

            <div className="block text-center mb-4 p-2 rounded-md text-foreground transition duration-300">
              Video Testimony
            </div>
          </div>

          {/* Video 3 - YouTube */}
          <div className="flex flex-col justify-center items-center gap-2">
            <iframe
              width="100%"
              height="192" // to match h-48
              src="https://www.youtube.com/embed/GwVJ6R95T4g"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-48"
            ></iframe>
            <div className="block text-center mb-4 p-2 rounded-md text-foreground transition duration-300">
              Video Testimony
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImpactSection;
