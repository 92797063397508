import React from "react";
import { Link } from "react-router-dom";
// import HeaderImage from "../../../components/HeaderImage";

const NationalPage: React.FC = () => {
  // const handleDocumentClick = () => {
  //   window.open(
  //     "/assets/pdf/Institutonal-Open-Science-Policy-Template.pdf",
  //     "_blank"
  //   );
  // };
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        Open Science requires open infrastructures that support the research
        community in sharing their publications, data, and software. LIBSENSE
        works with African countries to leverage NRENs and other strategic
        parties where they exist in the development and long-term sustainability
        of such open infrastructures and promote shared national platforms. For
        instance, LIBSENSE works with the national RENs of Nigeria, Ghana, and
        other international partners to develop, pilot, and scale national open
        access repositories, open publishing platforms, and persistent
        identifier services. LIBSENSE also fosters closer collaboration with
        local funding agencies, government frameworks, and international
        agencies required to deploy and maintain these infrastructures. Some
        open access infrastructures are{" "}
        <Link to="/shared-repositories" className="text-primary">
          BAOBAB
        </Link>
        ,{" "}
        <Link to="/pids-service" className="text-primary">
          PIDsLink
        </Link>
        ,{" "}
        <Link to="/open-data" className="text-primary">
          WEKO3 repository
        </Link>
        ,{" "}
        <Link to="/publishing-platforms" className="text-primary">
          PublishNow
        </Link>
        .
      </p>

      {/* Buttons */}
      {/* <div className="flex justify-center space-x-4 mt-8">
        <Link
          to="https://indico.wacren.net/category/41/"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Events
        </Link>
        <Link
          to="https://photos.wacren.net/index.php?/category/115"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Photos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Videos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Document
        </Link>
      </div> */}
    </>
  );
};

export default NationalPage;
