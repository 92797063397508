import React from "react";
// import { Link } from "react-router-dom";

const NationalPage: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        National Open Science Roadmaps is a strategic framework developed to
        guide African countries in adopting and implementing open science
        principles at the national level. These roadmaps outline specific
        actions, timelines, and responsibilities for integrating open science
        into research, education, and policy infrastructures.
      </p>

      <p className="text-sm text-foreground mb-4">
        Roadmaps aim to align national policies with international standards,
        such as UNESCO's Open Science Recommendations, while addressing local
        challenges and priorities.
      </p>
      <p className="text-sm text-foreground mb-4">
        The Roadmaps are developed through a collaborative process involving
        various stakeholders, including relevant government bodies, researchers,
        academics, research institutions, libraries, and funders. This ensures
        that all sectors contributing to the research ecosystem are aligned with
        open science objectives.
      </p>

      <p className="text-sm text-foreground mb-4">
        LIBSENSE supports institutions in adopting these national roadmaps by
        organising capacity-building workshops, providing policy guidelines, and
        offering advocacy support. This ensures that national-level commitments
        to open science are translated into actionable strategies at
        institutional levels.
      </p>

      <p className="text-sm text-foreground mb-4">
        The roadmaps emphasise the importance of open access to publications and
        research data, encouraging the development of infrastructure and
        policies that support data sharing, transparency, and collaboration.
        Through its support for National Open Science Roadmaps, LIBSENSE is
        helping African countries institutionalise open science, making research
        more transparent, accessible, and impactful.
      </p>

      {/* <div className="flex justify-center space-x-4 mt-8">
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Events
        </Link>
        <Link
          to="https://photos.wacren.net/index.php?/category/29"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Photos
        </Link>
        <Link
          to="https://video.wacren.net/channel/LIBSENSE/595033"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Videos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Document
        </Link>
      </div> */}
    </>
  );
};

export default NationalPage;
