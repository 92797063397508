import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";
import WorkingGroupForm from "./WorkingGroupForms";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Parent Component for all Working Groups
const WorkingGroups: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    const element = document.getElementById("working-group");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <HeaderImage />

      <div className="max-w-4xl mx-auto mb-12 p-4" id="working-group">
        <h2 className="text-center text-xxl font-bold text-foreground mb-8">
          Working Groups
        </h2>
        <p className="text-sm text-foreground mb-1">
          The LIBSENSE Working Groups are collaborative, cross-disciplinary
          teams that drive the key focal areas of LIBSENSE’s open science
          efforts across African research and higher education institutions and
          open science communities. The Working Groups comprise subject-matter
          experts to build capacity and help address critical challenges in
          implementing open science policies, infrastructures, and practices on
          the continent. By fostering dialogue and developing actionable
          strategies, the LIBSENSE Working Groups help to ensure that African
          institutions and the open science communities of practice are
          well-equipped to advance open science in Africa.
        </p>
        <p className="text-sm text-foreground mb-4">
          In each region, the survey aimed to:
        </p>

        <div className="space-y-8 mb-8">
          <PolicyWorkingGroup />
          <InfrastructureWorkingGroup />
          <CapacityBuilding />
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={toggleModal}
            type="button"
            className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Apply to join a working Group
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full relative">
            {/* Close button */}
            <button
              onClick={toggleModal}
              type="button"
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
            </button>
            <WorkingGroupForm onSuccess={toggleModal} />
          </div>
        </div>
      )}
    </>
  );
};

// Individual Working Group Components
const PolicyWorkingGroup: React.FC = () => {
  return (
    <div className="border rounded-lg p-6 bg-card flex gap-4 items-start transition-transform transform hover:scale-105 hover:bg-background hover:shadow-lg hover:border-text-primary duration-300">
      <img
        src="/assets/images/check.png"
        alt="check-mark"
        className="h-10 aspect-1"
      />
      <div>
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Policy, Governance and Leadership Working Group
        </h3>
        <p className="text-sm text-foreground mb-4">
          This Working Group for Policies, Governance and Leadership is
          dedicated to shaping frameworks that support the implementation of
          open science across African universities and research institutions.
          The group comprises experts, institutional leaders, and policymakers
          to develop strategies that ensure effective policies, governance and
          leadership in promoting open science.
        </p>
        <p className="text-sm text-foreground mb-4">
          Under policy development the Working Group focuses on helping to
          create/co-create and refine open science policies that align with
          international guidelines, such as UNESCO’s Open Science
          Recommendations, while addressing regional and institutional needs
          across Africa.
        </p>
        <p className="text-sm text-foreground mb-4">
          The group also provides guidance on governance structures that
          facilitate the implementation of open science at the institutional
          level. Through training and capacity-building efforts, the working
          group helps to empower university leadership to champion open science
          initiatives. The working group collaborates with regional
          organizations and networks to ensure that open science governance is
          embedded in both institutional and national research agendas. This
          includes engaging with key stakeholders such as the African Academy of
          Sciences, regional RENs (Research and Education Networks), and
          national research bodies.
        </p>
      </div>
    </div>
  );
};

const InfrastructureWorkingGroup: React.FC = () => {
  return (
    <div className="border rounded-lg p-6 bg-card flex gap-4 items-start transition-transform transform hover:scale-105 hover:bg-background hover:shadow-lg duration-300">
      <img
        src="/assets/images/check.png"
        alt="check-mark"
        className="h-10 aspect-1"
      />
      <div>
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Infrastructure Working Group (Network of Experts)
        </h3>
        <p className="text-sm text-foreground mb-4">
          The LIBSENSE Working Group on Infrastructure is focused on developing
          and supporting the technical foundations required to implement and
          enhance open science in African institutions. The Group’s key
          activities revolve around creating and improving infrastructure for
          open access to journals, repositories for research publications and
          data, and open discovery services. This ensures that African research
          is widely visible, accessible, and discoverable, thereby amplifying
          its impact.
        </p>
        <p className="text-sm text-foreground mb-4">
          Learn more about the work of the Working Group{" "}
          <Link to="/network-of-experts" className="text-primary">
            here
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

const CapacityBuilding: React.FC = () => {
  return (
    <div className="border rounded-lg p-6 bg-card flex gap-4 items-start transition-transform transform hover:scale-105 hover:bg-background hover:shadow-lg duration-300">
      <img
        src="/assets/images/check.png"
        alt="check-mark"
        className="h-10 aspect-1"
      />
      <div>
        <h3 className="text-lg font-semibold text-foreground mb-4">
          Capacity Building
        </h3>
        <p className="text-sm text-foreground mb-4">
          The Capacity Building Working Group focuses on empowering researchers,
          librarians, and institutional leaders across Africa with the skills
          and knowledge needed to implement and sustain open science practices.
          The group’s activities are designed to strengthen human resources and
          institutional capabilities in research data management, open access,
          and open science policy development.
        </p>
        <p className="text-sm text-foreground mb-4">
          The group’s strategic role is to advise on areas of knowledge gap and
          knowledge-deficient constituencies. This advice is instrumental in
          shaping capacity-building plans and considerations. Through regional
          and institutional collaborations, the working group facilitates the
          exchange of best practices in open science.
        </p>
        <p className="text-sm text-foreground mb-4">
          By providing targeted training and knowledge-sharing opportunities,
          the group helps African institutions build the skills to manage
          research outputs effectively, implement open access policies, and
          promote a culture of openness and collaboration.
        </p>
      </div>
    </div>
  );
};

export default WorkingGroups;
