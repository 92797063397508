import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Home from "./routes/Home";
import AboutUs from "./routes/About/AboutUs";
// import WhatWeDo from "./routes/WhatWeDo";
import Media from "./routes/Media";
import Resources from "./routes/Resources";
import ContactUs from "./routes/ContactUs";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import ProjectsInitiative from "./routes/About/Projects_Initiative";
import WorkingGroups from "./routes/About/WorkingGroups";
import Collaborations from "./routes/About/Collaborations";
import ImpactSection from "./routes/About/Impact";
import OpenPolicyPage from "./routes/WhatWeDo/OpenPolicy";
import CapacityBuildingPage from "./routes/WhatWeDo/CapacityBuilding";
import OpenAccessPage from "./routes/WhatWeDo/OpenAccess";
import SharedRepo from "./routes/Repo/SharedRepo";
import SharedPublishing from "./routes/Repo/SharedPublishing";
import OpenData from "./routes/Repo/OpenData";
import PIDsService from "./routes/Repo/PIDs";
import ScrollToTopButton from "./components/ScrollToTopBtn";
import NetworkOfExperts from "./routes/CallToAction/NetworkOfExperts";
import RDMPage from "./routes/RDM/Rdm";
import ECRGhanaPage from "./routes/RDM/ECRGhana";
import ECRNigeriaPage from "./routes/RDM/ECRNigeria";
import WorkshopDetail from "./routes/WhatWeDo/OPS/RegionalWorkshop";
import RuforumPage from "./components/workshop/Ruforum";
import NewsSection from "./routes/Media/News";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

const App: React.FC = () => {
  return (
    <div className="flex h-screen w-full overflow-hidden">
      <Router>
        <div className="flex grow flex-col content-start overflow-hidden bg-background">
          <Navbar />
          <div
            id="main-content"
            className="scrollbar-thumb-rounded scrollbar-track-rounded grow overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted"
          >
            <ScrollToTop />
            <ScrollToTopButton />
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/home" element={<Home />} />
              <Route
                path="/home/shared-repositories"
                element={<SharedRepo />}
              />
              <Route
                path="/home/publishing-platforms"
                element={<SharedPublishing />}
              />
              <Route path="/home/open-data" element={<OpenData />} />
              <Route path="/home/pids-service" element={<PIDsService />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route
                path="/about-us/project-initiatives"
                element={<ProjectsInitiative />}
              />
              <Route
                path="/about-us/working-groups"
                element={<WorkingGroups />}
              />
              <Route
                path="/about-us/collaborations"
                element={<Collaborations />}
              />
              <Route path="/about-us/impact" element={<ImpactSection />} />
              <Route
                path="/what-we-do/open-science-policy/:policyType"
                element={<OpenPolicyPage />}
              />
              <Route
                path="/what-we-do/open-science-policy"
                element={<OpenPolicyPage />}
              />
              <Route
                path="/what-we-do/open-infrastructure/:policyType"
                element={<OpenAccessPage />}
              />
              <Route
                path="/what-we-do/open-infrastructure"
                element={<OpenAccessPage />}
              />
              <Route
                path="/what-we-do/capacity-building/:policyType"
                element={<CapacityBuildingPage />}
              />
              <Route
                path="/what-we-do/capacity-building"
                element={<CapacityBuildingPage />}
              />
              <Route path="/media/news" element={<NewsSection />} />
              <Route path="/media/events" element={<Media />} />
              <Route path="/resources/*" element={<Resources />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route
                path="/network-of-experts"
                element={<NetworkOfExperts />}
              />
              <Route path="/rdm" element={<RDMPage />} />
              <Route path="/ecr-ghana" element={<ECRGhanaPage />} />
              <Route path="/ecr-nigeria" element={<ECRNigeriaPage />} />
              <Route path="/open-science/:title" element={<WorkshopDetail />} />
              <Route path="/ruforum" element={<RuforumPage />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
};
//ruforum/
export default App;
