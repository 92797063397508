// src/components/WorkingGroupForm.tsx
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { countriesWithLabelValue } from "../../utils/countries";

interface FormData {
  title: string;
  firstName: string;
  surname: string;
  email: string;
  gender: string;
  institution: string;
  country: string;
  workingGroup: string;
  profile: File | null;
  publications: string;
  contributions: string;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  firstName: Yup.string().required("First Name is required"),
  surname: Yup.string().required("Surname is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  institution: Yup.string().required("Institution is required"),
  country: Yup.string().required("Country is required"),
  workingGroup: Yup.string().required("Please select a working group"),
  publications: Yup.string().url("Invalid URL"),
  contributions: Yup.string().required("Please describe your contributions"),
});

interface MessageFormProps {
  onSuccess: () => void;
}

const JoinNetworkForm: React.FC<MessageFormProps> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const initialValues: FormData = {
    title: "",
    firstName: "",
    surname: "",
    email: "",
    institution: "",
    gender: "",
    country: "",
    workingGroup: "Infrastructure Working Group(Network of Experts)",
    profile: null,
    publications: "",
    contributions: "",
  };

  const handleSubmit = async (values: FormData) => {
    setLoading(true);
    const formDataToSend = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === "profile" && value) {
        formDataToSend.append(key, value);
      } else {
        formDataToSend.append(key, value as string);
      }
    });

    try {
      const response = await axios.post(
        "http://localhost:8080/api/libsense/apply-for-working-group",
        formDataToSend,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setLoading(false);
      alert(response.data.message);
      onSuccess();
    } catch (error) {
      setLoading(false);
      alert("Form submission failed.Try again after sometime");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue }) => (
        <Form className="mx-auto p-4 h-96 overflow-y-scroll">
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <Field
              as="select"
              name="title"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select Title</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Miss.">Miss.</option>
              <option value="Dr.">Dr.</option>
              <option value="Prof.">Prof.</option>
            </Field>
            <ErrorMessage
              name="title"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6">
            <div className="mb-4">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <Field
                type="text"
                name="firstName"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="surname"
                className="block text-sm font-medium text-gray-700"
              >
                Surname
              </label>
              <Field
                type="text"
                name="surname"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              <ErrorMessage
                name="surname"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <Field
              type="email"
              name="email"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="gender"
              className="block text-sm font-medium text-gray-700"
            >
              Gender
            </label>
            <Field
              as="select"
              name="gender"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select your gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Field>
            <ErrorMessage
              name="gender"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="institution"
              className="block text-sm font-medium text-gray-700"
            >
              Institution/Organisation
            </label>
            <Field
              type="text"
              name="institution"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
            <ErrorMessage
              name="institution"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="country"
              className="block text-sm font-medium text-gray-700"
            >
              Country
            </label>
            <Field
              as="select"
              name="country"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select Country</option>
              {countriesWithLabelValue.map((option) => (
                <option key={option.value.toString()} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name="country"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="workingGroup"
              className="block text-sm font-medium text-gray-700"
            >
              Choose the Working Group you wish to join
            </label>
            <Field
              as="select"
              name="workingGroup"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="Infrastructure Working Group(Network of Experts)">
                Infrastructure Working Group(Network of Experts)
              </option>
            </Field>
            <ErrorMessage
              name="workingGroup"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="profile"
              className="block text-sm font-medium text-gray-700"
            >
              Upload your profile
            </label>
            <input
              type="file"
              name="profile"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              onChange={(event) => {
                if (event.currentTarget.files) {
                  setFieldValue("profile", event.currentTarget.files[0]);
                }
              }}
            />
            <ErrorMessage
              name="profile"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="publications"
              className="block text-sm font-medium text-gray-700"
            >
              Link to your publications
            </label>
            <Field
              type="url"
              name="publications"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
            <ErrorMessage
              name="publications"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="contributions"
              className="block text-sm font-medium text-gray-700"
            >
              What contributions can you make in the Working Group?
            </label>
            <Field
              as="textarea"
              name="contributions"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
            <ErrorMessage
              name="contributions"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default JoinNetworkForm;
