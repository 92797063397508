import React from "react";

interface HeaderItem {
  image?: string;
}

const HeaderImage: React.FC<HeaderItem> = ({ image }) => {
  const displayImage = image ? image : "/assets/images/hero.jpeg";

  return (
    <div className="mt-10 w-full">
      <img
        src={`${displayImage}`}
        alt="header"
        className={`"w-full h-20" ${displayImage} ? "object-contain": "object-contain"`}
      />
    </div>
  );
};

export default HeaderImage;
