import React, { useState } from "react";
import { FaThLarge, FaList } from "react-icons/fa"; // Icons for Grid and List views

const NewsSection = () => {
  const [viewMode, setViewMode] = useState("list"); // State to manage view mode

  // Dummy data for news items
  const newsItems = [
    {
      id: 1,
      title:
        "Establishing a Diamond OA Publishing Ecosystem for Public Health Research in Africa: The Coalition for Open Access Publishing of Public Health in Africa (COPPHA)",
      time: "September 12, 2024",
      image:
        "/assets/images/news/Diamond-OA-Publishing-Ecosystem-for-Public-Health-Research-in-Africa.jpg",
    },
    {
      id: 2,
      title: "Côte d'Ivoire braces up to implement UNESCO",
      time: "5 mins ago",
      image: "https://libsense.ren.africa/path-to-thumbnail2.jpg",
    },
    {
      id: 3,
      title: "LIBSENSE expands compendium on open science case studies",
      time: "10 mins ago",
      image: "https://libsense.ren.africa/path-to-thumbnail3.jpg",
    },
  ];

  return (
    <div className="max-w-5xl mx-auto mb-8 pt-24">
      {/* News Title */}
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-xxxl font-bold text-foreground">News</h2>
          <p className="text-sm text-foreground">
            Stay updated with the latest news and events on open science.
          </p>
        </div>
        {/* View Toggle Icons */}
        <div className="flex space-x-4">
          <FaList
            className={`text-2xl cursor-pointer ${
              viewMode === "list" ? "text-black" : "text-gray-400"
            }`}
            onClick={() => setViewMode("list")}
          />
          <FaThLarge
            className={`text-2xl cursor-pointer ${
              viewMode === "grid" ? "text-black" : "text-gray-400"
            }`}
            onClick={() => setViewMode("grid")}
          />
        </div>
      </div>

      {/* News Items */}
      <div
        className={viewMode === "grid" ? "grid grid-cols-2 gap-8" : "space-y-8"}
      >
        {newsItems.map((item) => (
          <div
            key={item.id}
            className={`flex ${
              viewMode === "list" ? "flex-row space-x-4" : "flex-col"
            } border rounded-lg shadow-md`}
          >
            {/* Image with thumbnail */}
            <div
              className={`${
                viewMode === "list" ? "w-56 h-48" : "w-full h-48"
              } bg-gray-300 rounded-md flex-shrink-0`}
            >
              <img
                src={item.image}
                alt={item.title}
                className="object-cover w-full h-full rounded-md"
              />
            </div>

            {/* News Content */}
            <div
              className={`flex flex-col justify-between p-4 ${
                viewMode === "list" ? "" : "mt-4"
              }`}
            >
              <h3 className="text-lg font-semibold text-foreground mb-4">
                {item.title}
              </h3>
              <p className="text-sm text-foreground">{item.time}</p>
              <div className="flex justify-between items-center mt-2">
                <button className="hover:bg-primary-600 flex-none rounded-md bg-primary px-4 py-2.5 text-xs font-semibold text-primary-foreground">
                  Read More
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* News Archive Button */}
      <div className="flex justify-center mt-12">
        <button className="hover:bg-primary-600 flex-none rounded-lg bg-primary px-4 py-2.5 text-sm font-semibold text-primary-foreground">
          News Archives
        </button>
      </div>

      {/* Year Navigation */}
      <div className="flex justify-center space-x-4 mt-8">
        <button className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg">
          2024
        </button>
        <button className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg">
          2023
        </button>
        <button className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg">
          2022
        </button>
        <button className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg">
          2021
        </button>
      </div>
    </div>
  );
};

export default NewsSection;
