import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const AboutTimeline: React.FC = () => {
  const slides = [
    {
      title: "Empowering Africa Through Open Science",
      description:
        "LIBSENSE (Library Support for Embedded NREN Services and E-infrastructure) is a WACREN-initiated, community-driven, pan-African programme aimed at building a community of practice for open science...",
      buttonText: "Read More",
      link: "/about-us",
      imageUrl: "/assets/images/services/timeline1.png",
      showlabel: true,
    },
    {
      title: "Collaborate for Innovation",
      description:
        "Join the largest network of libraries and researchers in Africa and be part of a transformative movement in open science and research dissemination.",
      buttonText: "Join Us",
      link: "/join",
      imageUrl: "/assets/images/services/timeline2.png",
      showlabel: true,
    },
  ];

  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      autoPlay={true}
      interval={3000}
      showThumbs={false}
      showStatus={false}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className="h-[50vh] bg-contain bg-center"
          style={{ backgroundImage: `url(${slide.imageUrl})` }}
        ></div>
      ))}
    </Carousel>
  );
};

export default AboutTimeline;
