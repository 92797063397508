import React from "react";
import { Link } from "react-router-dom";
// import HeaderImage from "../../../components/HeaderImage";

const RegionalPage: React.FC = () => {
  // const handleDocumentClick = () => {
  //   window.open(
  //     "/assets/pdf/Institutonal-Open-Science-Policy-Template.pdf",
  //     "_blank"
  //   );
  // };
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE partners with research and education networks (RENs) to develop
        and manage tailored, community-driven open access (OA) platforms and
        services. These initiatives are focused on increasing the visibility of
        African scholarly outputs, enhancing the accessibility and
        interoperability of research data repositories and promoting the use of
        persistent identifiers to ensure the long-term discoverability of
        research outputs.{" "}
        <Link to="/shared-repositories" className="text-primary">
          BAOBAB
        </Link>
        ,{" "}
        <Link to="/pids-service" className="text-primary">
          PIDsLink
        </Link>
        , and{" "}
        <Link to="/publishing-platforms" className="text-primary">
          PublishNow
        </Link>
        , are all regional catchall infrastructures provided by WACREN.
      </p>

      {/* Buttons */}
      {/* <div className="flex justify-center space-x-4 mt-8">
        <Link
          to="https://indico.wacren.net/category/41/"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Events
        </Link>
        <Link
          to="https://photos.wacren.net/index.php?/category/115"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Photos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Videos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Document
        </Link>
      </div> */}
    </>
  );
};

export default RegionalPage;
