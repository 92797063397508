import React from "react";
// import { Link } from "react-router-dom";

const Institutional: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE and its early career teams are paving the way for a more open
        and collaborative research environment across the African region by
        equipping decision-makers with the knowledge and tools to implement
        effective policies.
      </p>
      <p className="text-sm text-foreground mb-4">
        Through these collective efforts, LIBSENSE is advancing open science and
        empowering a new generation of researchers committed to transparency,
        collaboration, and innovation in their fields.
      </p>

      {/* Buttons */}
      {/* <div className="flex justify-center space-x-4 mt-8">
        <Link
          to="https://indico.wacren.net/category/38/"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Events
        </Link>
        <Link
          to="https://photos.wacren.net/index.php?/category/29"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Photos
        </Link>
        <Link
          to="https://video.wacren.net/channel/LIBSENSE/595033"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Videos
        </Link>
        <Link
          to="/assets/pdf/Institutonal-Open-Science-Policy-Template.pdf"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Document
        </Link>
      </div> */}
    </>
  );
};

export default Institutional;
