import React from "react";
// import { Link } from "react-router-dom";

const RegionalPage: React.FC = () => {
  return (
    <>
      <p className="text-sm text-foreground mb-4">
        The LIBSENSE Regional Leadership Workshops bring together senior
        decision-makers and leaders from African higher education institutions.
        These workshops focus on advancing open science policies, advocacy, and
        governance within the region.
      </p>
      <p className="text-sm text-foreground mb-4">
        The workshops attract senior institutional leaders such as deputy
        vice-chancellors responsible for academic affairs and research,
        directors of postgraduate studies, and library directors. These
        stakeholders are critical in shaping and implementing open science
        policies at their institutions.
      </p>
      <p className="text-sm text-foreground mb-4">
        The workshops help participants understand how to translate global open
        science principles, such as UNESCO's Open Science Recommendations, into
        concrete policies tailored to their institutional and national contexts.
        Leaders are trained to advocate for open science within their
        institutions and at national levels, promoting transparency, open
        access, and collaboration in research. A key focus is on supporting
        institutional infrastructure, including libraries and digital
        repositories, to facilitate the practical implementation of open
        science.
      </p>
      <p className="text-sm text-foreground mb-4">
        The workshops are often held in collaboration with key regional
        organisations such as WACREN, ASREN, and UA, providing a coordinated and
        unified approach across different African regions. LIBSENSE also
        partners with organisations like AAU, AARU, CRUFAOCI, and SARUA to
        ensure the initiatives are deeply rooted in local and regional needs.
      </p>
      <p className="text-sm text-foreground mb-4">
        These workshops play a vital role in embedding open science into the
        fabric of research governance across Africa by equipping leaders with
        the skills and knowledge needed to champion it. This top-down approach
        helps drive sustainable change, ensuring open science becomes central to
        African research institutions.
      </p>

      {/* Buttons */}
      {/* <div className="flex justify-center space-x-4 mt-8">
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Events
        </Link>
        <Link
          to="https://photos.wacren.net/index.php?/category/29"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Photos
        </Link>
        <Link
          to="https://video.wacren.net/channel/LIBSENSE/595033"
          target="_blank"
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Videos
        </Link>
        <Link
          to=""
          className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
        >
          Document
        </Link>
      </div> */}
    </>
  );
};

export default RegionalPage;
